<template>
  <div class="app-container noticeList">
    <listStateGroup v-model="status" :list="tabs" class="mb30"></listStateGroup>
    <template v-for="item in tabs"> 
      <component
      v-if="status === item.value"
      :key="item.value"
      :is="item.value"
    ></component>
    </template>
  </div>
</template>

<script>
import { tabs } from './module/const'
import listStateGroup from '@/views/components/listStateGroup'
import anouncementList from './anouncementList'
import drafts from './drafts'
import recycled from './recycled'
export default {
  components: {
    listStateGroup,
    anouncementList,
    drafts,
    recycled
  },
  props: {},
  data() {
    return {
      tabs,
      status: 'anouncementList'
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped lang="scss">
.noticeList {
  font-size: 25px;
}
</style>
